import React, { useContext, useState } from 'react';
// import { NavBar } from 'components';

// import Footer from 'components/Footer';

import { referenceDataContext } from 'helpers';

import 'assets/css/bootstrap.min.css';
import 'assets/css/style.css';
import 'assets/css/responsive.css';
//import 'assets/css/bootstrap.css';
import logo from 'assets/images/logo.png';
import iconEmail from 'assets/images/icon-email.svg';
import iconRightArrow from 'assets/images/icon-arrow-right.svg';

import iconCall from 'assets/images/icon-call.svg';
import iconArrowRight from 'assets/images/icon-arrow-right.svg';
import logoWhite from 'assets/images/logo-white.png';
import iconPhone from 'assets/images/icon-phone.svg';
import iconMail from 'assets/images/icon-mail.svg';
import iconMap from 'assets/images/icon-map.svg';
import iconCta from 'assets/images/icon-cta.svg';



interface IProps {
  children: React.ReactNode
}

const PublicLayout = ({ children }: IProps) => {


  return (
    <>
      <header id="header-section" className="default-transition">
        <div className="container">
          <nav className="navbar navbar-expand-lg p-0">
            <a className="navbar-brand p-0" href="/"><img className="default-transition" src={logo} alt="logo" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sitemenu">
              <span className="navbar-toggler-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                  <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                </svg>
              </span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="sitemenu">
              <ul className="navbar-nav">
                <li className="nav-item"><a className="nav-link" href="/company">Chi Siamo</a></li>
                <li className="nav-item"><a className="nav-link" href="/pim">PIM</a></li>
                <li className="nav-item"><a className="nav-link" href="/solutions">Soluzioni software</a></li>

                <li className="nav-item d-block d-lg-none d-xl-none"><a className="nav-link" href="/contacts">Contattaci</a></li>
                <li className="nav-item d-block d-lg-none d-xl-none"><a className="nav-link" href="/jobs">Lavora con noi</a></li>
              </ul>
            </div>
            <div className="header-right">
              <a href="/contacts" className="header-icon default-gradient"><img src={iconEmail} alt="email" /></a>
              <a href="/jobs" className="default-btn default-gradient default-transition">Lavora con noi<img src={iconRightArrow} alt="icon" /></a>
            </div>
          </nav>

        </div>
      </header>

      {children}
      <div className="cta-section">
        <div className="container">
          <div className="row">
            <div className="col-md-8 cta-left">
              <div className="section-title"><h3>Contattaci per una consulenza gratuita</h3></div>
              <h4>Cerchi un partner affidabile in grado di guidare la tua azienda nel processo di innovazione digitale?</h4>
              <a href="/contacts" className="default-btn default-gradient default-transition">Contattaci<img src={iconArrowRight} alt="icon" /></a>
            </div>
            <div className="col-md-4 cta-right">
              <h3>Contattaci</h3>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-4 footer-box">
              <a href="/" className="footer-logo"><img src={logoWhite} alt="logo" /></a>
              {/*<p className="fshort-intro">Lorem ipsum dolor sit amet, consectetur adip iscing elit, sed do eiusmod tempor.</p>*/}
              <div className="footer-social">
                <a href="https://www.facebook.com/studiocassolato" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="32.169" height="32.169" viewBox="0 0 32.169 32.169">
                    <g className="icons8-facebook orange-icon" transform="translate(1.5 1.5)">
                      <path className="Path_3" data-name="Path 3" d="M28.52,34.126A14.6,14.6,0,0,1,7.077,15.6" transform="translate(-5.5 -7.638)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                      <path className="Path_4" data-name="Path 4" d="M13.9,7.865A14.341,14.341,0,0,1,21.862,5.5,14.6,14.6,0,0,1,36.447,20.085a14.362,14.362,0,0,1-1.971,7.332" transform="translate(-7.278 -5.5)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                      <path className="Path_5" data-name="Path 5" d="M16.788,25.614h3.153V36.02a11.944,11.944,0,0,0,4.73,0V25.614H27.51a.838.838,0,0,0,.788-.71l.315-2.365a1.485,1.485,0,0,0-.158-.631.9.9,0,0,0-.631-.237H24.672V18.913a1.581,1.581,0,0,1,1.577-1.577h1.577a.745.745,0,0,0,.788-.788v-2.68a.778.778,0,0,0-.71-.788c-.079,0-1.183-.079-2.6-.079-3.469,0-5.361,2.05-5.361,5.834v2.838H16.788A.745.745,0,0,0,16,22.46v2.365A.745.745,0,0,0,16.788,25.614Z" transform="translate(-7.722 -7.087)" fill="" />
                    </g>
                  </svg>
                </a>
                {/*<a href="#" target="_blank">*/}
                {/*  <svg xmlns="http://www.w3.org/2000/svg" width="30.592" height="30.593" viewBox="0 0 30.592 30.593">*/}
                {/*    <g className="icons8-instagram orange-icon" transform="translate(1.5 1.5)">*/}
                {/*      <path className="Path_14" data-name="Path 14" d="M34.092,18.01V14.384A7.907,7.907,0,0,0,26.209,6.5H14.384A7.907,7.907,0,0,0,6.5,14.384v2.365" transform="translate(-6.5 -6.5)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />*/}
                {/*      <path className="Path_15" data-name="Path 15" d="M6.5,25.6v4.651a7.907,7.907,0,0,0,7.884,7.884H26.209a7.907,7.907,0,0,0,7.884-7.884V26.625" transform="translate(-6.5 -10.542)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />*/}
                {/*      <path className="Path_16" data-name="Path 16" d="M22.2,15.5a6.7,6.7,0,1,0,6.7,6.7A6.7,6.7,0,0,0,22.2,15.5Z" transform="translate(-8.405 -8.405)" fill="none" stroke="" strokeWidth="3" />*/}
                {/*      <path className="Path_17" data-name="Path 17" d="M33.577,12a1.577,1.577,0,1,0,1.577,1.577A1.581,1.581,0,0,0,33.577,12Z" transform="translate(-11.897 -7.664)" fill="" />*/}
                {/*    </g>*/}
                {/*  </svg>*/}
                {/*</a>*/}
                {/*<a href="#" target="_blank">*/}
                {/*  <svg xmlns="http://www.w3.org/2000/svg" width="33.432" height="29.016" viewBox="0 0 33.432 29.016">*/}
                {/*    <g className="icons8-twitter orange-icon" transform="translate(1.5 1.075)">*/}
                {/*      <path className="Path_26" data-name="Path 26" d="M43.07,9.593a.72.72,0,0,0-.867-.158l-.158.079c-.079.079-.237.079-.315.158a8.075,8.075,0,0,0,.788-1.656.89.89,0,0,0-.237-.867.717.717,0,0,0-.867-.079,10.487,10.487,0,0,1-3.705,1.5L37,8.726a6.6,6.6,0,0,1,2.759,5.361V14.4l.079-.079a15.5,15.5,0,0,0,3.39-3.784A.838.838,0,0,0,43.07,9.593Z" transform="translate(-11.378 -6.961)" fill="" />*/}
                {/*      <path className="Path_27" data-name="Path 27" d="M13.96,33.5S11.2,35.865,4.5,35.865c0,0,2.759,3.153,10.249,3.153a16.679,16.679,0,0,0,11.983-4.73" transform="translate(-4.5 -12.577)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />*/}
                {/*      <path className="Path_28" data-name="Path 28" d="M11.334,12.915A10.607,10.607,0,0,1,7.471,8.5a7.07,7.07,0,0,0-1.183,3.942A6.262,6.262,0,0,0,9.442,17.96,9.565,9.565,0,0,1,5.5,17.172s.394,4.336,5.518,6.307l-3.153.788s1.577,2.759,6.307,3.942" transform="translate(-4.712 -7.286)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />*/}
                {/*      <path className="Path_29" data-name="Path 29" d="M33.042,24.292A21.677,21.677,0,0,0,35.8,14.2a6.7,6.7,0,1,0-13.4,0v1.183a19.5,19.5,0,0,1-4.1-.631" transform="translate(-7.421 -7.075)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />*/}
                {/*    </g>*/}
                {/*  </svg>*/}
                {/*</a>*/}
                <a href="https://it.linkedin.com/company/studio-cassolato-srls" target="_blank">
                  <svg xmlns="http://www.w3.org/2000/svg" width="29.016" height="29.016" viewBox="0 0 29.016 29.016">
                    <g className="icons8-linkedin orange-icon" transform="translate(1.5 1.5)">
                      <path className="Path_22" data-name="Path 22" d="M33.516,30.261v3.784A3.163,3.163,0,0,1,30.362,37.2H10.653A3.163,3.163,0,0,1,7.5,34.045V24.9" transform="translate(-7.5 -11.183)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                      <path className="Path_23" data-name="Path 23" d="M7.5,16.724v-6.07A3.163,3.163,0,0,1,10.653,7.5H30.362a3.163,3.163,0,0,1,3.153,3.153V21.69" transform="translate(-7.5 -7.5)" fill="none" stroke="" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" />
                      <ellipse className="Ellipse_5" data-name="Ellipse 5" cx="1.971" cy="1.971" rx="1.971" ry="1.971" transform="translate(4.336 4.336)" fill="" />
                      <path className="Path_24" data-name="Path 24" d="M16.153,31.825H13.788A.745.745,0,0,1,13,31.037V20.788A.745.745,0,0,1,13.788,20h2.365a.745.745,0,0,1,.788.788V31.037A.745.745,0,0,1,16.153,31.825Z" transform="translate(-8.664 -10.146)" fill="" />
                      <path className="Path_25" data-name="Path 25" d="M27.1,20a5.023,5.023,0,0,0-3.153,1.183v-.394A.745.745,0,0,0,23.153,20H20.788a.745.745,0,0,0-.788.788V31.037a.745.745,0,0,0,.788.788h2.365a.745.745,0,0,0,.788-.788V25.124a1.971,1.971,0,1,1,3.942,0v5.913a.745.745,0,0,0,.788.788h2.365a.745.745,0,0,0,.788-.788V24.73A4.744,4.744,0,0,0,27.1,20Z" transform="translate(-10.146 -10.146)" fill="" />
                    </g>
                  </svg>
                </a>
              </div>
              <br />
              <a href="https://www.iubenda.com/privacy-policy/44628708" className="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy ">Privacy Policy</a>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 footer-box flist-box">
              <h3>Link</h3>
              <p><a href="/">Home</a></p>
              <p><a href={'/company'}>Chi siamo</a></p>
              <p><a href={'/pim'}>PIM</a></p>
              <p><a href={'/solutions'}>Soluzioni software</a></p>
              <p><a href={'/contacts'}>Contattaci</a></p>
              <p><a href={'/jobs'}>Lavora con noi</a></p>


            </div>
            <div className="col-12 col-sm-6 col-lg-4 footer-box flist-box flist-details">
              <h3>Studio Cassolato Srls</h3>
              <h5>Sede Operativa</h5>
              <p><img src={iconMap} />Piazza della Serenissima, 40 <br />c/o Coffice Business Center<br />31033 Castelfranco Veneto (TV)</p>
              <h5>Sede Legale</h5>
              <p><img src={iconMap} />Viale Cairoli 27/F<br /> 31100 Treviso (TV)</p>


            </div>
          </div>
          <p className="copyright-text text-center">&copy; {new Date().getFullYear()} Studio Cassolato Srls unipersonale, Partita IVA e Codice Fiscale IT 04715500262</p>
        </div>
      </footer>

    </>
  );
}

export default PublicLayout;