import React, { useEffect, useState } from 'react';

import ListPage from 'components/ListPage';
import { Link } from 'react-router-dom';
import softwareSvgRepo from 'assets/images/software-svgrepo-com.svg';

let Consulting = () => {
    return (
        <>
            <ListPage
                title='Studio Cassolato'
                subtitle='Consulenza IT e software'
                description="Il nostro studio eroga diversi servizi di consulenza IT, cloud e software, finalizzati ad aumentare l'efficienza e la competitività delle aziende"
            >
                <>
                        <a href={'/consulting/cloud'}>
                            <p className="whatwedo-servicebox-one"><img src={softwareSvgRepo} alt="services" />  <span>Consulenza Cloud</span></p>
                        </a>
                        <a href={'/consulting/architecture'}>
                            <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="services" />  <span>Consulenza Architettura software</span></p>
                        </a>
                </>
            </ListPage>
        </>
    );
}

export default Consulting;