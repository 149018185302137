import { BrowserRouter } from 'react-router-dom';



import { Routes, ScrollReset } from 'components';


function App() {
    return (
        <BrowserRouter>
            <ScrollReset />
            <Routes />
        </BrowserRouter>
    );
}
 
export default App;
