import React, { useEffect, useState } from 'react';
import iconArrowRight from 'assets/images/icon-arrow-right.svg';

let Contacts = () => {

    return (
        <>

            <div className="contactus-section">
                <div className="container">
                    <div className="contact-form-block">
                        <div className="section-title"><h3>Get In Touch</h3></div>
                        <h3 className="section-header">Contact Us</h3>
                        <p>Are You Looking For Enterprise Software Consulting? Choose which option that works best for you...</p>
                        <form>
                            <div className="mb-3">
                                <label className="form-label">Full Name</label>
                                <input type="text" className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Email</label>
                                <input type="email" className="form-control" />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Your request</label>
                                <textarea className="form-control" rows={3}></textarea>
                            </div>
                            <div className="mb-3 text-center">
                                <button type="submit" className="default-btn default-gradient default-transition">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="google-map-block">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d235014.15049961975!2d72.5797426!3d23.0202434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C%20Gujarat!5e0!3m2!1sen!2sin!4v1692953183182!5m2!1sen!2sin"
                            style={{border: 0}}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                            </iframe>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Contacts;