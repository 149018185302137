import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let Estimator = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Software per la preventivazione</h3>

                            <p>Questo software è progettato per <strong>automatizzare la creazione dei preventivi</strong>, riducendo il rischio di errori e i tempi di attesa dei clienti. In pochi secondi è possibile generare documenti dettagliati e dal layout professionale, gestendo listini multipli e scontistiche personalizzate e inserendo servizi ad hoc per ogni singola offerta.</p>
                            
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Personalizzazione avanzata</h5>
                            <p>
                            Il software permette di calcolare automaticamente il prezzo di vendita del prodotto <strong>in base alla configurazione scelta</strong>, gestendo l’inserimento di diversi parametri e specifiche tecniche. Nel caso in cui una quotazione includa sia l’acquisto di prodotti che l’erogazione di servizi correlati (pensiamo ad esempio al montaggio di un macchinario) il sistema è in grado di calcolare l’importo finale secondi i parametri inseriti, inserendo il dettaglio delle <strong>attività incluse in ogni servizio</strong>.    </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Riduzione degli errori</h5>
                            <p>
                            Grazie alle funzioni avanzate di calcolo automatico, è possibile creare in pochi minuti dei <strong>preventivi corretti e dettagliati</strong>, limitando il rischio di errori. 
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Quotazioni più chiare ed efficaci</h5>
                            <p>
                            Il cliente riceve un documento completo di descrizioni, schede tecniche e foto dei prodotti noleggiati o acquistati, più eventuali informazioni aggiuntive inserite manualmente dall’operatore.
                            </p>
                        </div>
                    </div>
                  
                </>
            </DetailsPage>
        </>
    );
}

export default Estimator;