import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let Architecture = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Consulenza architettura software</h3>

                            <p>Supportiamo le imprese nella progettazione e nello sviluppo della migliore architettura software in termini di prestazioni, costi, scalabilità e sicurezza.</p>
                            <p>Un'infrastruttura IT solida e performante permette di migliorare la gestione e la manutenibilità delle risorse tecnologiche dell'azienda, di prevenire gli incidenti e di garantire la business continuity anche in situazioni di crisi</p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Progettazione dell'architettura</h5>
                            <p>
                                Guidiamo i clienti in un <b>percorso di digitalizzazione sicuro e sostenibile</b>, progettando infrastrutture informatiche funzionali, efficienti e flessibili sulla base delle specifiche esigenze aziendali.
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Costruzione dell'architettura</h5>
                            <p>
                                Attraverso lo sviluppo di soluzioni ad hoc e l'eventuale uso di risorse IT preesistenti, implementiamo <b>architetture moderne, sicure e altamente performanti</b>, in grado di aumentare l'efficienza e la produttività.
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Sviluppo di software custom-designed</h5>
                            <p>
                                Progettiamo <b>soluzioni digitali personalizzate</b> finalizzate a ottimizzare l'infrastruttura esistente e semplificare le attività dell'''organizzazione.
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Integrazione con i sistemi in uso</h5>
                            <p>
                                Ove possibile, integriamo i nuovi software modificando le infrastrutture preesistenti, al fine di contenere i costi e le tempistiche di sviluppo.
                            </p>
                        </div>
                    </div>
                </>
            </DetailsPage>
        </>
    );
}

export default Architecture;