import React, { useState } from 'react';


interface IReferenceDataContext {
    menuItems: IMenuItem[]
    footerMenuItems: IMenuItem[]
}
interface IMenuItem {
    url: string
    name: string
}

export let referenceDataContext = React.createContext({} as IReferenceDataContext);

let { Provider } = referenceDataContext;

let ReferenceDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {


    let [menuItems] = useState<{ url: string, name: string }[]>([
        { url: '/method', name: 'Metodo' },
        { url: '/services', name: 'Servizi' },
        { url: '/contacts', name: 'Contatti' },
        { url: '/careers', name: 'Lavora con noi' }
    ]);

    let [footerMenuItems] = useState<{ url: string, name: string }[]>([
        { url: '/method', name: 'Metodo' },
        { url: '/services/customSoftware', name: 'Software su misura' },
        { url: '/services/pim', name: 'PIM' },
        { url: '/services/consultancy', name: 'Consulenza' },
        { url: '/contacts', name: 'Contatti' },
        { url: '/careers', name: 'Lavora con noi' }
    ]);


    return (
        <Provider value={{
            menuItems,
            footerMenuItems
        }}>
            {children}
        </Provider>
    )
}

export default ReferenceDataProvider;