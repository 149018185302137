import { ITab } from 'helpers/tabs';

let subMenu : ITab[] = [ 
    { title: 'OMS', url: '/solutions/oms' },
    { title: 'After Sales', url: '/solutions/after-sales' },
    { title: 'Hybrid Commerce', url: '/solutions/hybrid-commerce' },
    { title: 'Pianificatore', url: '/solutions/scheduling' },
    { title: 'Ticket Assistenza', url: '/solutions/support-tickets' },
    { title: 'CRM B2B', url: '/solutions/CRM' },
    { title: 'Preventivatore', url: '/solutions/estimator' },
    { title: 'Rubrica Aziendale', url: '/solutions/company-directory' },
    { title: 'DAM', url: '/solutions/dam' },
];

export default subMenu ;