import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let SupportTickets = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Software per la gestione dei ticket di assistenza</h3>

                            <p>I <strong>software di ticketing</strong> (Ticketing System) sono progettati per migliorare la <strong>gestione delle richieste di assistenza</strong> della clientela grazie a una piattaforma centralizzata, accessibile sia da PC che da smartphone. Ogni sistema di ticketing viene sviluppato ad hoc a seconda delle esigenze specifiche di ogni azienda e integrato con i sistemi aziendali esistenti</p>
                            <p>La conversione in ticket di tutte le richieste di assistenza <strong>provenienti da diversi canali</strong> (telefono, email, web, app dedicata) permette di monitorare, dare priorità, risolvere e archiviare i ticket da un’unica piattaforma. Qualora siano necessarie delle attività aggiuntive (ad esempio l’intervento tecnico presso il cliente finale o il reso di un prodotto) il sistema è in grado di gestirle interfacciandosi direttamente con gli altri software in uso. </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Migliora l’efficienza e i costi del Customer Service</h5>
                            <p>
                            L’integrazione con i sistemi aziendali esistenti (CRM, ERP, Knowledge Base) consente agli operatori di accedere a tutte le informazioni riguardanti il cliente e il prodotto/servizio in questione e allo storico dei ticket precedenti da<strong> un’unica piattaforma</strong>. Il miglioramento dell’efficienza operativa permette di <strong>ridurre sensibilmente i costi</strong> relativi al Customer Care.        </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Migliora la customer retention, aumenta il fatturato</h5>
                            <p>
                            Grazie a questi software è possibile fornire risoluzioni più rapide, <strong>migliorando il livello di soddisfazione della clientela</strong> e aumentando il suo tasso di fidelizzazione, con effetti positivi sulla brand reputation e sul fatturato.   </p>
                        </div>
                    </div>
                
                </>
            </DetailsPage>
        </>
    );
}

export default SupportTickets;