import { ReferenceDataProvider } from 'helpers';
import PublicLayout from './PublicLayout';

interface IProps {
    children: React.ReactNode
}

const Route = ({ children }: IProps) => {
    return (
        <ReferenceDataProvider>
            <PublicLayout>
                {children}
            </PublicLayout>
        </ReferenceDataProvider>
    );
}

export default Route;