import React, { useEffect, useState } from 'react';

import ListPage from 'components/ListPage';
import { Link } from 'react-router-dom';
import softwareSvgRepo from 'assets/images/software-svgrepo-com.svg';

let Solutions = () => {
    return (
        <>

            <div className="what-we-do-section alt-servicepage">
                <div className="container">
                    <div className="section-title"><h3>Soluzioni software</h3></div>
                    <h3 className="section-header">Soluzioni custom designed</h3>

                    <div className="section-description">
                        <p>Scopri alcune delle soluzioni software sviluppate su misura per i nostri clienti.</p>
                    </div>
                    <div className="whatwedo-services">

                        <>

                            <a href={'/solutions/oms'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="oms" />  <span>OMS - Order Management System</span></p>
                            </a>

                            <a href={'/solutions/after-sales'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="after sales" />  <span>Gestione post-vendita</span></p>
                            </a>
                            <a href={'/solutions/hybrid-commerce'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="hybrid commerce" />  <span>Soluzioni Hybrid Commerce</span></p>
                            </a>
                            <a href={'/solutions/scheduling'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="scheduling" />  <span>Pianificatore attività</span></p>
                            </a>
                            <a href={'/solutions/support-tickets'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="support tickets" />  <span>Gestione ticket assistenza</span></p>
                            </a>
                            <a href={'/solutions/crm'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="b2b crm" />  <span>CRM per aziende B2B</span></p>
                            </a>
                            <a href={'/solutions/estimator'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="services" />  <span>Preventivatore software</span></p>
                            </a>
                            <a href={'/solutions/company-directory'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="company directory" />  <span>Rubrica aziendale contatti</span></p>
                            </a>
                            <a href={'/solutions/dam'}>
                                <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="dam" />  <span>DAM - Gestione asset digitali</span></p>
                            </a>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Solutions;