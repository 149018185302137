
import { ITab } from 'helpers/tabs';
import React from 'react';
import { Link } from 'react-router-dom';

interface IPage {
    tabs?: ITab[]
    children?: React.ReactNode
}

const Page = ({ tabs, children, ...rest }: IPage) => {
    return (
        <div className="service-single-section">
            <div className="container">
                <div className="service-single-contentarea">
                    {children}
                </div>
                {tabs && tabs?.length > 0 &&
                    <div className="service-single-sidebar">
                        <div className="service-details-listing">
                            {tabs.map((t, ix) =>
                                <a key={ix} href={t.url}>{t.title}</a>
                                //<Link key={ix} to={t.url}>{t.title}</Link>
                            )}
                        </div>
                    </div>
                }
            </div>
        </div>



    );
};

export default Page;
