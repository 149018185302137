import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let HybridEcommerce = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Soluzioni Hybrid Commerce</h3>

                            <p>L'<strong>hybrid commerce</strong> permette a manufacturer, distributori e retailer di unire i vantaggi dello shopping in negozio a quelli dell’e-commerce, allo scopo di offrire <strong>un’esperienza innovativa e personalizzata </strong>alla clientela. </p>
                            <p>Le nostre soluzioni avanzate raccolgono e organizzano le informazioni provenienti dai diversi touchpoint fisici e digitali, integrando tutti i canali di vendita e di logistica aziendali secondo un <strong>approccio omnicanale</strong>.</p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Migliora la customer experience</h5>
                            <p>
                            Grazie a una gestione unificata e ottimizzata di tutti i canali di vendita, è possibile sfruttare al massimo il loro potenziale, raggiungendo <strong>nuovi potenziali clienti</strong> e offrendo loro <strong>un’esperienza di acquisto</strong> memorabile.       </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Abbatti i silos organizzativi</h5>
                            <p>
                            Le nostre soluzioni per <strong>l’hybrid commerce</strong> consentono di consultare la disponibilità dei prodotti nei vari magazzini e di visualizzare o modificare gli ordini eseguiti dalla piattaforma e-commerce o da altri store fisici.  Grazie all’integrazione della supply chain in un’ottica omnichannel, è possibile <strong>ottimizzare tutti i processi aziendali</strong>, dalla gestione del magazzino fino alle operazioni di vendita e al servizio customer care.    </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Aumenta le vendite</h5>
                            <p>
                            L’app per dispositivi Android permette ai clienti presenti in un negozio fisico di acquistare i prodotti disponibili sul canale e-commerce del rivenditore direttamente da tablet o cellulare. L’app può essere utilizzata anche per <strong>raccogliere gli ordini durante fiere</strong>, meeting ed eventi aziendali, inserendo i dati di pagamento e l’indirizzo del cliente finale.     </p>
                        </div>
                    </div>
                   
                </>
            </DetailsPage>
        </>
    );
}

export default HybridEcommerce;