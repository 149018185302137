
import React from 'react';
import { Link } from 'react-router-dom';
// import softwareSvgRepo from 'assets/images/software-svgrepo-com.svg';


interface IPage {
    title?: string
    subtitle?: string
    description?: string
    children?: React.ReactNode
}

const Page = ({ title, subtitle, description, children, ...rest }: IPage) => {
    return (
        
    <div className="what-we-do-section alt-servicepage">
    <div className="container">
        {title && <div className="section-title"><h3>{title}</h3></div>}
        {subtitle && <h3 className="section-header">{subtitle}</h3>}
        {description && <div className="section-description">{description}</div>}
        
        <div className="whatwedo-services">
          {/* <a href="#">
            <p className="whatwedo-servicebox-one"><img src={softwareSvgRepo} alt="services"/>  <span>Software Development Consulting</span></p>
          </a>
          <a href="#">
            <p className="whatwedo-servicebox-two"><img src={softwareSvgRepo} alt="services"/>  <span>Software Architecture Consulting</span></p>
          </a>
          <a href="#">
            <p className="whatwedo-servicebox-three"><img src={softwareSvgRepo} alt="services"/>  <span>Order Management Software</span></p>
          </a> */}
          {children}
        </div>
    </div>
  </div>

    );
};

export default Page;
