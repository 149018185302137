import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let CompanyDirectory = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Rubrica contatti aziendale</h3>

                            <p>I software per la creazione di una <strong>rubrica contatti aziendale</strong> raccolgono in un’unica piattaforma digitale tutte le informazioni relative a clienti, partner, fornitori e dipendenti. </p>
                            <p>In questo modo il personale ha a disposizione una banca dati sempre <strong>aggiornata e consultabile</strong> in qualunque momento, sia da PC che da smartphone. Le informazioni possono essere acquisite direttamente da CRM, ERP e Active Directory e sono visualizzabili e modificabili solo dagli utenti autorizzati.   </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Diritti di accesso personalizzabili</h5>
                            <p>
                            È possibile configurare il sistema allo scopo di <strong>limitare l’accesso ai dati</strong> secondo diversi criteri, ad esempio il ruolo aziendale o il portafoglio clienti assegnato a ciascun agente commerciale.            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Biglietti da visita digitali </h5>
                            <p>
                            Il sistema permette di generare dei <strong>biglietti da visita digitali</strong> per la condivisione dei dati personali attraverso un semplice link o QR code.     </p>
                        </div>
                    </div>
                  
                </>
            </DetailsPage>
        </>
    );
}

export default CompanyDirectory;