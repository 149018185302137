import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let Method = () => {
    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Il nostro metodo di sviluppo</h3>
                            <ul className="services-single-listing">


                                <li><strong>Analisi</strong> – La comprensione del modello di business dell’azienda cliente e dello scenario competitivo in cui opera ci aiuta a identificare le sfide da affrontare, le risorse da mettere in campo e gli obiettivi da raggiungere.</li>
                                <li><strong>Esplorazione</strong> – Dopo l’analisi iniziale, prendiamo in esame le diverse soluzioni tecnologiche al fine di identificare quella più adatta a soddisfare le esigenze dell’organizzazione.</li>
                                <li><strong>Sviluppo</strong> – Il nostro team, guidato da un Project Manager dedicato, inizia a sviluppare le soluzioni individuate nel rispetto delle tempistiche e dei criteri definiti con il cliente.</li>
                                <li><strong>Testing</strong> – I nostri metodi di controllo garantiscono da subito dei sistemi tecnologici sicuri, stabili e altamente performanti.</li>
                                <li><strong>Rilascio</strong> – Durante e dopo il rilascio del software, assistiamo il cliente nella transizione al nuovo sistema e monitoriamo il funzionamento dei vari applicativi per migliorare le prestazioni e risolvere eventuali bug.</li>
                                <li><strong>Manutenzione e aggiornamento</strong> – Le attività periodiche di aggiornamento, implementazione di nuove funzionalità e risoluzione di eventuali criticità garantiscono delle infrastrutture tecnologiche sempre avanzate, efficienti e flessibili.</li >
                            </ul>
                        </div>
                    </div>
            
                </>
            </DetailsPage >
        </>
    );
}

export default Method;