import React, { useEffect, useState } from 'react';

import ListPage from 'components/ListPage';
import { Link } from 'react-router-dom';
import softwareSvgRepo from 'assets/images/software-svgrepo-com.svg';

let Jobs = () => {
    return (
        <>
            <div className="container">
                <div className="contact-form-block">
                    <div className="section-title"><h3>Lavora con noi</h3></div>
                    <h3 className="section-header">Perchè lavorare con noi?</h3>

                    <ul className="services-single-listing">
                        <li>Ambiente di lavoro tranquillo</li>
                        <li>Possibilità di frequentare corsi e conferenze, anche scelti da te
                        </li>
                        <li>Portatile, monitor, tastiera e mouse di qualità, per permetterti di lavorare al meglio
                        </li>
                        <li>Possibilità di guadagnare di più grazie al nostro programma di bonus</li>
                        <li>Siamo preparati ai cambiamenti e li accogliamo sempre volentieri
                        </li>

                    </ul>


                </div>
                <div className="contact-form-block">
                    <div className="section-title"><h3>Posizioni aperte</h3></div>
                    <h3 className="section-header">Tirocinio formativo web C# / Javascript</h3>
                    <h5>Junior / Esperienza 1+ anni</h5>
                    <p>Il candidato ideale ha una conoscenza, anche base, di C# e/o Javascript ed ha un approccio curioso nei confronti della programmazione di applicazioni web.</p>

                    <p>Il candidato durante lo stage seguirà un percorso di formazione che lo porterà a saper realizzare in autonomia piccole web application, affrontando temi quali cloud, database, integrazioni via API con altri sistemi. È richiesta una conoscenza buona della lingua inglese.</p>
                    <p>Al termine del tirocinio il candidato idoneo sarà inserito come dipendente a tempo indeterminato. Il tirocinio è retribuito.</p>

                </div>
                <div className="contact-form-block">


                    <h3 className="section-header">Junior developer web C# / Javascript</h3>
                    <h5>Junior / Esperienza 1+ anni</h5>
                    <p>                    <p>Il candidato ideale ha una conoscenza, anche base, di C# e/o Javascript ed ha un approccio curioso nei confronti della programmazione di applicazioni web.</p>
                    </p>
                    <p>La persona che cerchiamo ha già utilizzato framework quali ReactJS e asp.net, conosce Microsoft SQL Server o MongoDb ed è predisposto al miglioramento continuo delle proprie conoscenze. È richiesta una conoscenza buona della lingua inglese.</p>
                    <p>La posizione prevede contratto a tempo indeterminato (CCNL Commercio) con retribuzione basata sull’esperienza.</p>

                </div>
                <div className="contact-form-block">
                    <h3 className="section-header">Senior developer web C# / Javascript</h3>
                    <h5>Senior / Esperienza 5+ anni</h5>
                    <p>Il candidato ideale è una persona motivata, che sa lavorare in team e che ha a cuore gli interessi dei nostri clienti.<br />
                        Oltre alle attività di sviluppo, sarà di supporto nelle attività di analisi e gestione dei progetti, oltre a coordinare le attività di DevOps e supporto ai progetti esistenti.</p>
                    <p><strong>Responsabilità</strong></p>
                    <ul>
                        <li>Scrivere codice chiaro e ben manutenibile</li>
                        <li>Organizzare le attività di rilascio (deploy), anche interfacciandosi con i clienti</li>
                        <li>Partecipare in maniera attiva all'analisi iniziale e ai momenti di confronto interni (stand up meeting, grooming sessions) e con i clienti (weekly calls)</li>
                        <li>Gestire PR e Code Review</li>
                        <li>Rimanere aggiornato su nuove tecnologie e nuovi pattern per suggerirli durante le attività quotidiane di sviluppo</li>
                    </ul>






                    <p><strong>Requisiti</strong></p>
                    <ul>
                        <li> Laurea in Informatica, Ingegneria Informatica o simili; in alternativa: esperienza equivalente.</li>
                        <li>3 anni o più di esperienza in una posizione similie</li>
                        <li>3 anni o più di esperienza nello sviluppo con C#</li>
                        <li>Buona conoscenza dei database Microsoft SQL Server, MongoDb</li>
                        <li>Buona conoscenza della lingua inglese</li>
                        <li>Buona conoscenza di GIT</li>
                        <li>Residenza a Castelfranco Veneto o entro 40 km</li>

                    </ul>







                    <p><strong> Costituiscono titolo preferenziale le conoscenze di</strong></p>
                    <ul>
                        <li>Metodologie Agile/Scrum, Kanban o simili</li>
                        <li>Strumenti di Unit testing/Integration testing</li>
                        <li>Typescript</li>
                        <li>ReactJs</li>
                        <li>Servizi Cloud Amazon AWS & Microsoft Azure</li>
                    </ul>





                </div>


                <div className="contact-form-block">
                    <div className="section-title"><h3>Come candidarsi</h3></div>
                    <p>Invia il tuo curriculum a jobs@studiocassolato.it; se hai un GitHub personale ci fa piacere se lo includi nel tuo messaggio</p>
                </div>

            </div>

        </>
    );
}

export default Jobs;