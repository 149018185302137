import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let OMS = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>OMS - Order Management System</h3>

                            <p>I nostri <strong>Order Management System</strong> (software per la gestione degli ordini) permettono di tracciare e raccogliere tutti i dati relativi agli <strong>acquisti effettuati</strong> su piattaforme e-commerce, marketplace di terze parti e punti vendita fisici in un unico luogo. </p>
                            <p>Interfacciandosi con i sistemi ERP (Enterprise Resource Planning) e WMS (Warehouse Management System), forniscono <strong>informazioni sempre aggiornate</strong> riguardanti l’inserimento e l’evasione degli ordini, la gestione dell’inventario, i pagamenti e il servizio post-vendita. </p>
                            <p>
Offrendo una <strong>visione completa delle attività svolte</strong> dalle singole divisioni (vendite, finanza, logistica, customer care), l’OMS consente di abbattere i silos organizzativi, facilitare i processi decisionali e migliorare l’esperienza offerta al cliente finale.
</p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Gestione magazzino</h5>
                            <p>
                            Combinando le informazioni provenienti da varie fonti sulla disponibilità dei prodotti - magazzino, fornitori, e-commerce, rivenditori - il software offre una <strong>visione unificata degli stock</strong> aggiornata in tempo reale.  Grazie alla sua flessibilità, è in grado di adattarsi alle diverse piattaforme di vendita e agli eventuali limiti tecnologici del sistema ERP, al fine di ridurre le differenze di inventario, i backorder e gli ordini annullati per mancanza di merce.     </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Marketing Automation</h5>
                            <p>
                            L’integrazione con i più comuni sistemi di Marketing Automation e con i software CRM e ERP rende possibile l’invio di <strong>email relative agli acquisti effettuati</strong> (conferma ordine, spedizione, resi, ecc.) o altre comunicazioni personalizzate alla clientela. </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Customer Care</h5>
                            <p>
                            Il servizio clienti può consultare rapidamente tutti gli ordini ricevuti attraverso le diverse piattaforme direttamente all’interno dell’OMS, senza necessità di collegarsi all’ERP aziendale. In questo modo può fornire un supporto più rapido e preciso e <strong>ridurre i tempi di risposta</strong>, aumentando il livello di soddisfazione dei clienti.  </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Business Intelligence</h5>
                            <p>
                            L’integrazione con i principali sistemi di Business Intelligence consente di avere una visione completa <strong>sull’intero ciclo di vita di un ordine</strong>, dall’immissione fino alla consegna, in un’unica piattaforma. La <strong>creazione di report</strong> riguardanti gli ordini, la gestione dell'inventario e il servizio post-vendita fornisce insight utili all’ottimizzazione della supply chain e al miglioramento della customer experience.    </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Gestione pagamenti</h5>
                            <p>
                        
Interfacciandosi con <strong>diversi gateway di pagamento</strong> (ad esempio Stripe, Adyen, PayPal) il software gestisce i pagamenti pre-autorizzati e il rimborso degli ordini cancellati, bloccando temporaneamente la disponibilità a magazzino di prodotti ordinati con pagamento rateale o bonifico bancario.
</p>    </div>
                    </div>
                </>
            </DetailsPage>
        </>
    );
}

export default OMS;