import { useState } from 'react';
import checkIcon from 'assets/images/icon-check-all.svg';
import axios from 'axios';

interface IContactUs {
    name: string
    email: string
    message: string
    privacyOk: boolean
}

let Contacts = () => {

    const formDefault: IContactUs = {
        email: '',
        message: '',
        name: '',
        privacyOk: false
    };

    const [isLoading, setLoading] = useState<boolean>(false);
    const [emailSent, setMailSent] = useState<boolean>(false);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [contactData, setContactData] = useState<IContactUs>({ ...formDefault });

    const sendMail = async () => {
        setMailSent(false);
        try {
            if (contactData.name !== '' && contactData.email !== '' && contactData.privacyOk) {
                setLoading(true);

                let request = {
                    name: contactData.name,
                    email: contactData.email,
                    message: contactData.message,
                    privacyOk: contactData.privacyOk
                }

                let response = await axios.post('/api/EmailRequest/SendContactInfoEmail', request);

                setShowErrors(false);
                setContactData({ ...formDefault });
                setMailSent(true);
            } else {
                setShowErrors(true);
            }
        } catch (e) {

        } finally {
            setLoading(false);
        }
    }

    return (
        <>

            <div className="contactus-section">
                <div className="container">
                    <div className="contact-form-block">
                        <div className="section-title"><h3>Contattaci</h3></div>
                        <p>Vuoi parlarci del tuo prossimo progetto? Compila il modulo sottostante per concordare un primo appuntamento</p>
                        <form className='needs-validation' noValidate onSubmit={(e) => { sendMail(); e.preventDefault(); }}>
                            {isLoading !== true && emailSent !== true &&
                                <>
                                    <div className="mb-3">
                                        <label
                                            className={`form-label ${(showErrors === true && !contactData.name) ? 'text-danger' : ''}`}
                                            htmlFor="name">
                                            Nome e cognome
                                        </label>
                                        <input
                                            id="name"
                                            name="name"
                                            type="text"
                                            className={`form-control ${(showErrors === true && !contactData.name) ? 'is-invalid' : ''}`}
                                            value={contactData.name}
                                            required
                                            onChange={(e) => setContactData({ ...contactData, name: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            className={`form-label ${(showErrors === true && !contactData.email) ? 'text-danger' : ''}`}
                                            htmlFor="email">
                                            Email
                                        </label>
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            className={`form-control ${(showErrors === true && !contactData.email) ? 'is-invalid' : ''}`}
                                            value={contactData.email}
                                            required
                                            onChange={(e) => setContactData({ ...contactData, email: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label
                                            className={`form-label ${(showErrors === true && !contactData.message) ? 'text-danger' : ''}`}
                                            htmlFor="message">
                                            Messaggio</label>
                                        <textarea
                                            id="message"
                                            name="message"
                                            className={`form-control ${(showErrors === true && !contactData.message) ? 'is-invalid' : ''}`}
                                            rows={3}
                                            value={contactData.message}
                                            required
                                            onChange={(e) => setContactData({ ...contactData, message: e.target.value })}
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <div className="form-check">
                                            <input
                                                className={`form-check-input ${(showErrors === true && !contactData.privacyOk) ? 'is-invalid' : ''}`}
                                                id="privacyOk"
                                                name="privacyOk"
                                                type="checkbox"
                                                checked={contactData.privacyOk}
                                                onChange={(e) => setContactData({ ...contactData, privacyOk: e.target.checked })}
                                            />
                                            <label className={`form-check-label ${(showErrors === true && !contactData.privacyOk) ? 'text-danger' : ''}`} htmlFor="privacyOk">
                                                Ho letto l'Informativa sulla Privacy e presto il consenso per quanto riguarda la finalità di essere ricontattato.
                                            </label>
                                        </div>
                                    </div>

                                    <div className="mb-3 text-center">
                                        <button type="submit" className="default-btn default-gradient default-transition">Invia messaggio</button>
                                    </div>

                                </>
                            }

                            {showErrors === true &&
                                <div className="mb-3 text-center">
                                    <span className="text-danger">Si prega di compilare tutti i campi obbligatori</span>
                                </div>
                            }

                            {isLoading === true &&
                                <div className="alert alert-primary" role="alert">
                                    <span className="spinner-grow spinner-grow-sm text-primary" role="status" />
                                    <span>&nbsp;Invio messaggio in corso...</span>
                                </div>
                            }

                            {isLoading !== true && emailSent === true &&
                                <div className="alert alert-success" role="alert">
                                    <img src={checkIcon} alt="" />&nbsp;Messaggio inviato
                                </div>
                            }

                        </form>
                    </div>
                    <div className="google-map-block">
                        <iframe
                            title='studioCassolatoMap'
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2787.397634681519!2d11.940535815772225!3d45.68299162683797!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47792937513fee59%3A0xfa605d12bd941a87!2sStudio%20Cassolato!5e0!3m2!1sit!2sit!4v1669392540403!5m2!1sit!2sit"
                            style={{ border: 0 }}
                            allowFullScreen
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade">
                        </iframe>
                    </div>
                </div>
            </div>


        </>
    );
}

export default Contacts;