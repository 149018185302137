import { Navigate, Route, Routes as Router } from 'react-router-dom';
import { SiteRoute } from 'components';


import {
    Architecture,
    Cloud,
    Company,
    History,
    Mission,
    Method,
    Consulting,
    Contacts,
    Homepage,
    Pim,
    Solutions,
    OMS,
    CRM,
    AfterSales,
    HybridCommerce,
    Scheduling,
    SupportTickets,
    Estimator,

    DAM,
    Jobs,
    JobOffer,
    CompanyDirectory
} from 'views';


let Routes = () => {
    return (
        <Router>
            <Route path="/" element={<SiteRoute children={<Homepage />} />} />

            <Route path={'/solutions'} element={<SiteRoute children={<Solutions />} />} />
            <Route path={'/solutions/oms'} element={<SiteRoute children={<OMS />} />} />
            <Route path={'/solutions/after-sales'} element={<SiteRoute children={<AfterSales />} />} />
            <Route path={'/solutions/hybrid-commerce'} element={<SiteRoute children={<HybridCommerce />} />} />
            <Route path={'/solutions/scheduling'} element={<SiteRoute children={<Scheduling />} />} />
            <Route path={'/solutions/support-tickets'} element={<SiteRoute children={<SupportTickets />} />} />
            <Route path={'/solutions/estimator'} element={<SiteRoute children={<Estimator />} />} />
            <Route path={'/solutions/company-directory'} element={<SiteRoute children={<CompanyDirectory />} />} />
            <Route path={'/solutions/dam'} element={<SiteRoute children={<DAM />} />} />
            <Route path={'/solutions/crm'} element={<SiteRoute children={<CRM />} />} />

            <Route path={'/company'} element={<SiteRoute children={<Company />} />} />

            <Route path={'/company/history'} element={<SiteRoute children={<History />} />} />
            <Route path={'/company/mission'} element={<SiteRoute children={<Mission />} />} />
            <Route path={'/company/method'} element={<SiteRoute children={<Method />} />} />

            <Route path={'/consulting'} element={<SiteRoute children={<Consulting />} />} />
            <Route path={'/consulting/cloud'} element={<SiteRoute children={<Cloud />} />} />
            <Route path={'/consulting/architecture'} element={<SiteRoute children={<Architecture />} />} />

            <Route path={'/pim'} element={<SiteRoute children={<Pim />} />} />
            <Route path={'/services/pim'} element={<SiteRoute children={<Pim />} />} />

            <Route path={'/contacts'} element={<SiteRoute children={<Contacts />} />} />
            <Route path={'/jobs'} element={<SiteRoute children={<Jobs />} />} />
            <Route path={'/jobs/:joboffer'} element={<SiteRoute children={<JobOffer />} />} />
            <Route path={'/careers'} element={<Navigate to={"/jobs"} />} />

            <Route path="*" element={<Navigate to={"/error404"} />} />
        </Router>
    )
}

export default Routes;