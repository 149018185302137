import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let AfterSales = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Piattaforme di servizio al post-vendita</h3>

                            <p>Le nostre piattaforme e mobile app per la <strong>gestione della fase post-vendita</strong> permettono al cliente di usufruire del servizio di assistenza fornito dal rivenditore o dalla casa madre durante il periodo di garanzia di un prodotto. </p>
                            <p>Registrandosi all’interno dell’area riservata, che può condividere lo stesso login del sistema ecommerce, gli utenti possono accedere a servizi quali:</p>
                            <ul className="services-single-listing">
<li>Registrazione dei prodotti acquistati offline o su piattaforme di terze parti</li>
<li>Accesso ai manuali tecnici e a tutorial/articoli di approfondimento sul prodotto</li>
<li>Acquisto di ricambi</li>
<li>Visualizzazione del periodo di garanzia e possibilità di acquistare un’estensione di garanzia </li>
<li>Possibilità di acquistare articoli correlati o compatibili con il prodotto scelto</li>
                                </ul>

                                <p>L’integrazione di queste piattaforme all’interno dei sistemi aziendali consente di <strong>rendere più efficiente il servizio post-vendita</strong> e di migliorare il livello di soddisfazione e fidelizzazione della clientela. </p>
                                <p>Inoltre, incentivando l’utente a effettuare nuovi acquisti direttamente sulla piattaforma e-commerce dell’azienda, è possibile <strong>aumentare le vendite</strong> attraverso la tecnica del cross-selling.</p>
                        </div>
                    </div>
                  
                </>
            </DetailsPage>
        </>
    );
}

export default AfterSales;