import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let Cloud = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Consulenza Cloud</h3>

                            <p>
                                Supportiamo le imprese che desiderano implementare <b>soluzioni cloud</b> per migliorare l'efficienza dei processi aziendali e ottenere un vantaggio competitivo in termini di costi e agilità organizzativa.
                            </p>
                            <p>
                                Dalla strategia iniziale all'implementazione e gestione dei singoli sistemi, i nostri consulenti guidano le aziende verso una <b>migrazione al cloud fluida e priva di rischi</b>, supportandole in ogni aspetto tecnico e logistico.
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>I nostri servizi di consulenza Cloud</h5>
                            <ul>
                                <li>
                                    Modifiche ai sistemi esistenti per strutturare le potenzialità del Cloud (storage flessibile a block/blob), configurazione di strumenti per l'invio di email, monitoraggio delle performance e degli errori, utilizzo di strumenti PaaS (Azure App Service, database).
                                </li>
                                <li>
                                    Accesso ai software da reti aziendali (Hybrid Cloud, private gateway).
                                </li>
                                <li>
                                    Analisi delle infrastrutture cloud in uso per determinare possibili riduzioni di costi, senza compromettere le prestazioni e la sicurezza dei dati
                                </li>
                            </ul>
                        </div>
                    </div>
                </>
            </DetailsPage>
        </>
    );
}

export default Cloud;