import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let CRM = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>CRM per aziende B2B</h3>

                            <p>Il <strong>software CRM</strong> raccoglie le informazioni riguardanti le <strong>interazioni con i clienti attuali e potenziali</strong> di un’azienda, provenienti da ogni touchpoint fisico o digitale, all’interno di un database centralizzato. </p>
                            <p>. I dati raccolti possono supportare le aziende in diverse attività, dalla vendita di prodotti e servizi alla pianificazione di campagne marketing, fino al servizio di assistenza alla clientela. Vediamo quali sono i <strong>benefici offerti da un sistema CRM per le aziende con clienti B2B</strong>.  </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Un’unica fonte di dati per tutta l’azienda</h5>
                            <p>
                            Interfacciandosi con i diversi sistemi aziendali, il CRM permette di <strong>centralizzare informazioni fondamentali</strong> sui clienti attuali e potenziali (interazioni, offerte, ordini, pagamenti, richieste di assistenza) garantendo una <strong>maggiore completezza e visibilità</strong> dei dati, un minor rischio di errori e un miglioramento del servizio alla clientela.</p>
                            <p>
                            I dati salvati all’interno della piattaforma possono facilitare il lavoro del reparto vendite, del team marketing e degli operatori del customer care, ma sono utilizzabili anche dai manager aziendali per prendere <strong>decisioni data-driven</strong>.   
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Uno strumento per facilitare la progressione dei lead</h5>
                            <p>
                            I sistemi CRM aiutano il team Sales a tracciare grandi quantità di dati riguardanti i <strong>lead</strong> e a rispondere meglio alle loro esigenze specifiche. In questo modo è possibile monitorare <strong>l’avanzamento dei prospect</strong> lungo il funnel di vendita e favorire la loro progressione attraverso l’adozione di iniziative specifiche, concentrando gli sforzi sui contatti a maggiore potenziale.              </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Campagne di comunicazione personalizzate</h5>
                            <p>
                            Le informazioni raccolte all’interno del CRM possono essere utilizzate dal reparto marketing per creare <strong>campagne promozionali personalizzate</strong>, ad esempio in base alla posizione dei lead nella pipeline di vendita o alle loro preferenze di acquisto. 
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Ottimizzazione della customer experience</h5>
                            <p>
                            Grazie a una conoscenza più approfondita delle esigenze e delle preferenze di prospect e clienti, il personale aziendale può fornire un <strong>servizio personalizzato</strong>, migliorando le relazioni con il pubblico e aumentando il livello di soddisfazione e fidelizzazione della clientela.      </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Reportistica avanzata</h5>
                            <p>
                            L’integrazione con i principali software di Business Intelligence rende possibile la creazione di <strong>report dettagliati basati su grandi volumi di dati</strong> provenienti da più fonti. </p>
                        </div>
                    </div>
                </>
            </DetailsPage>
        </>
    );
}

export default CRM;