import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let History = () => {
    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Storia</h3>

                            <p>Studio Cassolato nasce dall’esperienza ventennale di Nicola Cassolato nel settore dello <strong>sviluppo software</strong> e della <strong>consulenza informatica</strong>.</p>
                            <p> Negli anni, il nostro team di consulenti ha aiutato aziende di diversi settori e dimensioni a migliorare la loro produttività ed espandere il loro business attraverso delle soluzioni tecnologiche altamente performanti, sicure e scalabili.

                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            
                            
                                <p>2004-2008 – Nicola Cassolato avvia la sua attività di consulente informatico e sviluppatore software freelance per il settore delle piccole-medie imprese, specializzandosi nella realizzazione di siti Internet e applicazioni web.</p>
                                <p>2009-2014 – Nicola amplia il suo portafoglio clienti e inizia a sviluppare progetti che presto diventano lo strumento software più utilizzato nelle aziende </p>
                                <p>2015 – Viene fondato lo Studio Cassolato: l’attività della società si concentra sulla consulenza e lo sviluppo di software custom per le aziende che hanno bisogno di soluzioni a supporto della digitalizzazione e dell'eCommerce.</p>
                                <p>2016-2019 – Con l’assunzione di nuovi collaboratori, il team espande e perfeziona le proprie competenze tecniche ed adotta una rigorosa metodologia sia per i progetti di sviluppo sia per le importanti attività di supporto</p>
                                <p>2020-2021 – In seguito alla pandemia di Covid-19, lo Studio sviluppa le soluzioni digitali più adatte a garantire la continuità operativa anche in situazioni di crisi.</p>
                                <p>2023 – Dall’esperienza acquisita nello sviluppo di soluzioni digitali per il mondo dell’e-commerce, nasce il PIM (Product Information Management) di Studio Cassolato, progettato per le aziende che vogliono semplificare la gestione dei dati di prodotto e aumentare le vendite sfruttando diversi canali digitali e fisici. </p>


                        </div>
                    </div>
                    
                </>
            </DetailsPage>
        </>
    );
}

export default History;