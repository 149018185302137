import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let DAM = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Gestione asset digitali (DAM)</h3>

                            <p>I <strong>software DAM</strong> (Digital Asset Management) garantiscono una gestione efficiente e centralizzata degli <strong>asset digitali</strong> di un’azienda. </p>
                            <p>Integrabili con i sistemi ERP e CRM aziendali, consentono di sviluppare, distribuire, organizzare e archiviare le varie risorse multimediali (file video, audio e immagini) in modo rapido ed efficace. </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Facilita la collaborazione tra team </h5>
                            <p>
                            Grazie alla creazione di una <strong>libreria multimediale unificata</strong> e ai filtri di ricerca avanzati, le organizzazioni possono migliorare la collaborazione tra i diversi team di lavoro, snellendo i processi di ricerca ed elaborazione dei contenuti.  </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Proteggi il patrimonio digitale dell’azienda</h5>
                            <p>
                            Affidati a una piattaforma sicura e scalabile per l’archiviazione e la condivisione delle risorse multimediali. Il sistema di <strong>gestione dei diritti di accesso</strong> e di utilizzo protegge i file da spiacevoli incidenti, limitando il rischio di errori o perdite di dati.  </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Valorizza la brand image</h5>
                            <p>
                            Semplifica i processi di revisione e approvazione degli asset digitali, assicurando dei <strong>contenuti di qualità</strong> e coerenti all’immagine e ai valori del brand.     </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Personalizza i contenuti multimediali</h5>
                            <p>
                            Le risorse multimediali possono essere completate con <strong>l’aggiunta di metadati</strong>, ad esempio il numero della commessa, il nome del cliente, l’autore del video/foto, o altre informazioni utili. La ricerca full text consente di <strong>inserire dei tag</strong> nelle fotografie per descrivere persone o oggetti all’interno della foto e di eseguire delle <strong>modifiche semplici</strong> ai file archiviati, ad esempio la rotazione dell’immagine o la conversione di un video in diversi formati.       </p>
                        </div>
                    </div>
                </>
            </DetailsPage>
        </>
    );
}

export default DAM;