import subMenu from './submenu';
import { DetailsPage } from '../../components';

let Company = () => {
    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Chi siamo</h3>
                            <p>Siamo una tech company specializzata nella realizzazione di <strong>soluzioni software custom-designed</strong>, progettate in base alle esigenze specifiche del singolo cliente.</p>
                            <p> Grazie allo sviluppo di funzionalità avanzate e di integrazioni complesse, guidiamo le imprese in un percorso di digitalizzazione in grado di ottimizzare tutti i processi aziendali, aumentare la redditività e rendere i sistemi informativi capaci di adattarsi velocemente alle esigenze di business.</p>
                        </div>
                    </div>

                </>
            </DetailsPage>
        </>
    );
}

export default Company;