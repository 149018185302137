import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let Scheduling = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Pianificazione attività</h3>

                            <p>I <strong>software di pianificazione attività</strong> aiutano a pianificare rapidamente <strong>l’esecuzione di servizi professionali</strong>, gestendo in modo efficiente i singoli collaboratori e i diversi team di lavoro</p>
                            <p>Le funzionalità di questi applicativi vengono personalizzate a seconda delle esigenze del singolo cliente, delle attività da svolgere e del settore di riferimento. </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Pianificazione centralizzata</h5>
                            <p>
                            Un <strong>unico cruscotto grafico</strong> permette di verificare i turni di lavoro dei vari membri del team, assegnare i compiti alle singole risorse, monitorare il carico di lavoro e visualizzare l’elenco delle attività da programmare. In caso di eventi inattesi (ad esempio ferie o malattia) che richiedono cambiamenti al programma già inserito, è possibile ripianificare rapidamente gli interventi, rispettando le scadenze stabilite.     </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Controllo dei costi</h5>
                            <p>
                            Il monitoraggio dei costi legati all’impiego delle varie risorse consente di tenere sotto controllo le spese e di eseguire una prima stima sulla <strong>marginalità della commessa</strong>.    </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Reportistica dettagliata</h5>
                            <p>
                            Tramite l’app per dispositivi mobili, le squadre professionali possono accedere alle informazioni inserite dal Project Manager, inserire le <strong>ore di lavoro prestate</strong>, fornire <strong>report dettagliati</strong> sull’attività svolta e richiedere eventuali <strong>rimborsi spesa</strong>.      </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Fatturazione rapida e precisa</h5>
                            <p>
                            Sulla base dei dati inseriti e del feedback dei collaboratori, è possibile eseguire in modo semplice e veloce il calcolo consuntivo dei lavori svolti ed emettere la <strong>fattura al cliente finale</strong>.     </p>
                        </div>
                    </div>
                </>
            </DetailsPage>
        </>
    );
}

export default Scheduling;