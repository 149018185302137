import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';
import subMenu from './submenu';


let Mission = () => {

    return (
        <>
            <DetailsPage tabs={subMenu}>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Vision</h3>

                            <p>
                                Siamo convinti che un impiego intelligente e razionale delle tecnologie digitali a disposizione possa avere un impatto positivo non solo sul mondo del business, ma sulla <strong>qualità della vita</strong> di ognuno di noi.</p>
                            <p> La rivoluzione digitale può migliorare il modo in cui lavoriamo, comunichiamo, acquistiamo beni e servizi e trascorriamo il nostro tempo libero, semplificando molte azioni quotidiane, abbattendo le barriere fisiche e aprendo scenari inediti.
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>Mission</h3>
                            <p>
                                Aiutiamo le imprese a cogliere le opportunità offerte dall’innovazione digitale, guidandole verso un <strong>processo di trasformazione tecnologica</strong> finalizzato a ridurre i rischi, a gestire le complessità dei processi aziendali e a migliorare la competitività.
                            </p><p>
                                Sulla base di un dialogo e di un <strong>confronto costante con i nostri clienti</strong>, progettiamo e sviluppiamo soluzioni e infrastrutture software su misura delle loro specifiche esigenze. Solo così riusciamo a realizzare degli applicativi in grado di offrire un <strong>valore concreto</strong> alle organizzazioni: riduzione dei costi, miglioramento della customer experience, aumento della produttività, superamento delle barriere fisiche e geografiche e adozione di strategie data-driven.

                            </p>
                        </div>
                    </div>
                </>
            </DetailsPage>
        </>
    );
}

export default Mission;