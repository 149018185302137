import React, { useEffect, useState } from 'react';
import { DetailsPage } from 'components';


let Pim = () => {
    return (
        <>
            <DetailsPage>
                <>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h3>SC PIM</h3>
                            <p>SC PIM è un software PIM (Product Information Management) progettato per <strong>organizzare e aggiornare le informazioni di prodotto</strong> all’interno di un’interfaccia semplice e intuitiva. L’integrazione con ogni tipologia di database, dal file Excel ai software ERP aziendali, consente di sincronizzare ed estrarre i dati provenienti da varie fonti, raccogliendoli in un’<strong>unica piattaforma centralizzata</strong>.</p>

                            <p>SC PIM può essere configurato in base alle esigenze specifiche dell’organizzazione e agli altri applicativi utilizzati, con l’obiettivo di <strong>semplificare la gestione delle informazioni di prodotto</strong> e aumentare le vendite sui diversi canali fisici e digitali.</p>



                        </div>
                       
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Un unico HUB accessibile a tutta l’azienda</h5>
                            <p>
                                Tutti i reparti aziendali (acquisti, vendite, marketing e assistenza clienti) potranno attingere a un <strong>unico database aggiornato in tempo reale</strong>. I costi e i tempi legati alla gestione di cataloghi, listini, schede tecniche, manuali, piattaforme e-commerce e marketplace vengono ridotti sensibilmente, così come il rischio di errori e incongruenze nelle informazioni di prodotto
                            </p>
                        </div>
                    </div>

                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Flussi di lavoro personalizzati</h5>
                            <p>
                                La <strong>personalizzazione dei workflow</strong> permette di verificare quali schede prodotto sono pronte per la pubblicazione e quali invece devono essere ancora completate. Il software è integrabile con le seguenti piattaforme: Google Shopping, Amazon, eBay, Facebook, Magento, Trovaprezzi.it, Lengow e Highstreet.
                            </p>
                        </div>
                    </div>

                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Condivisione dei dati sicura</h5>
                            <p>
                                Grazie alle funzioni di <strong>controllo degli accessi</strong> e della visibilità, è possibile condividere i dati di prodotto in modo semplice, rapido e sicuro, limitando le possibilità di modifica a specifici utenti o gruppi anche in base al brand o ad altre informazioni del prodotto.
                            </p>
                        </div>
                    </div>
                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Organizzazione più efficiente</h5>
                            <p>
                                Semplifica la gestione dei flussi di lavoro interni e <strong>facilita la collaborazione</strong> con distributori, partner e fornitori attraverso un tool in grado di <strong>sincronizzare in tempo reale</strong> tutte le informazioni di prodotto sui diversi canali di vendita digitali e fisici. 
                                
                            </p>
                        </div>
                    </div>

                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Informazioni sempre aggiornate</h5>
                            <p>
                                Le schede prodotto vengono pubblicate automaticamente sui diversi canali di vendita e supporti cartacei: listini, cataloghi, piattaforme e-commerce, marketplace, siti di comparazione prezzi e POS di negozi fisici. I dati possono essere <strong>personalizzati in base alla piattaforma di destinazione</strong>, ad esempio scegliendo il prezzo di listino o impostando delle scontistiche predefinite.
                            </p>
                        </div>
                    </div>

                    <div className="service-single-contentblock fulwidth">
                        <div className="service-single-contentbox">
                            <h5>Le funzionalità più apprezzate </h5>
                            <ul className="services-single-listing">
                                <li>Interfaccia semplice e intuitiva</li>
                                <li>Gestione di contenuti in qualsiasi lingua o localizzazione</li>
                                <li>Gestione di listini multipli, prodotti singoli, varianti, bundle e prodotti configurabili</li>
                                <li>Possibilità di associare a ogni articolo dei file digitali (immagini, modelli 3D per realtà aumentata, video) e di gestirli attraverso alcune funzionalità DAM (Digital Asset Management)</li>
                                <li>Controllo preciso degli accessi, anche sul singolo campo e per singoli gruppi di prodotto</li>
                                <li>Regole di validazione personalizzabili per ogni canale/piattaforma, allo scopo di rilevare e correggere eventuali incongruenze o informazioni mancanti.</li>
                                <li>Import/export su Excel, bulk edit di qualsiasi campo</li>

                            </ul>
                        </div>
                    </div>

                
                </>
            </DetailsPage>
        </>
    );
}

export default Pim;